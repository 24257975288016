import { gql } from '@apollo/client';

export const deleteBookmarkQuery = gql`
  mutation DeleteBookmark($itemId: ID!) {
    deleteBookmark(itemId: $itemId) {
      bookmark {
        itemId
      }
      errors {
        message
        path
      }
    }
  }
`;

export const createBookmarkQuery = gql`
  mutation CreateBookmark($itemId: ID!) {
    createBookmark(itemId: $itemId) {
      bookmark {
        itemId
      }
      errors {
        message
        path
      }
    }
  }
`;

export const deleteCompanyQuery = gql`
  mutation LogOut {
    logOut {
      profile {
        ... on CompanyProfile {
          id
        }
      }
      errors {
        message
      }
    }
  }
`;

export const sendAccessToken = gql`
  mutation Auth($accessToken: String!) {
    companyAuth(accessToken: $accessToken) {
      company {
        id
        name
        phone
      }
      errors {
        message
        path
      }
    }
  }
`;

export const createReportQuery = gql`
  mutation CreateReport(
    $clientMutationId: String
    $itemId: ID!
    $reportReasonId: ID!
    $description: String
  ) {
    createReport(
      input: {
        clientMutationId: $clientMutationId
        itemId: $itemId
        reportReasonId: $reportReasonId
        description: $description
      }
    ) {
      report {
        id
      }
      errors {
        code
        message
        path
      }
    }
  }
`;

export const createPaymentQuery = gql`
  mutation CreatePayment(
    $clientMutationId: String
    $paidServiceOptionId: ID!
    $paymentMethodId: ID!
    $targetId: ID!
    $targetType: PaidServiceTargetTypeEnum!
    $platform: PaymentCreatePlatformEnum
  ) {
    createPayment(
      input: {
        clientMutationId: $clientMutationId
        paidServiceOptionId: $paidServiceOptionId
        paymentMethodId: $paymentMethodId
        targetId: $targetId
        targetType: $targetType
        platform: $platform
      }
    ) {
      status
      redirectUrl
      errors {
        code
        message
        path
      }
    }
  }
`;

export const payForItemQuery = gql`
  mutation PayForItem($clientMutationId: String, $itemId: ID!) {
    payForItem(
      input: { clientMutationId: $clientMutationId, itemId: $itemId }
    ) {
      item {
        state
      }
      errors {
        code
        message
        path
      }
    }
  }
`;

export const prolongItemQuery = gql`
  mutation ProlongItem($clientMutationId: String, $itemId: ID!) {
    prolongItem(
      input: { clientMutationId: $clientMutationId, itemId: $itemId }
    ) {
      item {
        state
      }
      errors {
        code
        message
        path
      }
    }
  }
`;

export const sendAnalyticsDataQuery = gql`
  mutation CreateAnalyticLog(
    $clientMutationId: String
    $targetId: ID!
    $sourceLink: String
    $triggerButton: String
    $phones: [String!]
    $targetType: AnalyticLogTargetTypeEnum!
    $eventType: AnalyticLogEventTypeEnum!
    $source: AnalyticLogSourceEnum!
  ) {
    createAnalyticLog(
      input: {
        clientMutationId: $clientMutationId
        targetId: $targetId
        targetType: $targetType
        sourceLink: $sourceLink
        triggerButton: $triggerButton
        phones: $phones
        eventType: $eventType
        source: $source
      }
    ) {
      errors {
        code
        message
        path
      }
    }
  }
`;
